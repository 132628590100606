import { template as template_b895d8c5d8434e82afe6190c66fc0e83 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b895d8c5d8434e82afe6190c66fc0e83(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
