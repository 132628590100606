import { template as template_4a46d36c28d249a1808a353afed0fc42 } from "@ember/template-compiler";
const WelcomeHeader = template_4a46d36c28d249a1808a353afed0fc42(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
